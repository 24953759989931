import "./home.css";

import React from "react";
import { Button } from "@material-ui/core";
import { Helmet } from "react-helmet";

import { ENTRY_POINT } from "../../utils/stateTypes";
import { getEntryPoint } from "../../utils/entryStorage";
import testimonialsData from "./testimonialsData";
// import Specials from "../sections/Specials";
// import specialsData from "./specialsData";
import Header from "../sections/Header";
import BlockGallery from "../sections/BlockGallery";
import CoverFlowGallery from "../sections/CoverFlowGallery";
import LineSeparator from "../sections/LineSeparator";
import BlockHeading from "../sections/BlockHeading";
import Testimonials from "../sections/Testimonials";
import GreenBlockSection from "../sections/GreenBlockSection";
import VideoSection from "../sections/VideoSection";

const GALLERY_IMAGES_1 = {
  [ENTRY_POINT.DEFAULT]: [
    { src: "/images/block-gallery-1/Feb-2025-4.jpg", alt: "A long table with flower center pieces in the Glass Marquee at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Feb-2025-3.jpg", alt: "Confetti being thrown on a young couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Feb-2025-5.jpg", alt: "An arched bouquet of flowers at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Feb-2025-1.jpg", alt: "The famous Glass Marquee at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(39).jpg", alt: "A selection of various wedding flowers at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(44).jpg", alt: "A couple standing before a minister at an outdoor wedding ceremony at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(51).jpg", alt: "A close up of a beautiful dark-themed wedding at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(55).jpg", alt: "A beautiful sunny day at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(62).jpg", alt: "A floral arrangment in front of a stain-glass window at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(80).jpg", alt: "A couple saying 'I do' in front of family and friends at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(101).jpg", alt: "The sun shining onto the marble floors at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(114).jpg", alt: "An African bride smiling in front of a stain-glass window at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(134).jpg", alt: "The sun shining onto wedding table decorations at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(165).jpg", alt: "A bride in a black dress at her non-conventional wedding at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Feb-2025-2.jpg", alt: "A beautiful couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(183).jpg", alt: "Black floral arrangements and decor at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.CORPORATE]: [
    { src: "/images/corporate/Corporate_2.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_3.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_13.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_4.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_6.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_17.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_18.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_19.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    { src: "/images/bdiamond/Bdmnd_6.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_2.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_30.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_8.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_38.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_32.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_31.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_3.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_20.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_4.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_29.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_14.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_6.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_21.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_16.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_27.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    { src: "/images/loveislove/LoveIsLove_3.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_15.jpg", alt: "A beautiful wedding setup for a same-sex marriage at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_10.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_17.jpg", alt: "A beautiful wedding setup for a same-sex marriage at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_20.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_29.jpg", alt: "A beautiful wedding setup for a same-sex marriage at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_5.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_4.jpg", alt: "A beautiful wedding setup for a same-sex marriage at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_19.jpg", alt: "A beautiful wedding setup for a same-sex marriage at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_6.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_30.jpg", alt: "A beautiful wedding setup for a same-sex marriage at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_14.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_13.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_18.jpg", alt: "A beautiful wedding setup for a same-sex marriage at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_4.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_19.jpg", alt: "A beautiful wedding setup for a same-sex marriage at Shepstone Gardens & The Great Hall" },
  ],
};

const GALLERY_IMAGES_2 = {
  [ENTRY_POINT.DEFAULT]: [
    { src: "/images/block-gallery-1/Feb-2025-6.jpg", alt: "A newly married couple walking outdoors at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Feb-2025-7.jpg", alt: "A beautiful modern bride standing in a old building at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Feb-2025-8.jpg", alt: "A newly married couple walking outdoors at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Feb-2025-9.jpg", alt: "A beautiful modern bride standing in a old building at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Feb-2025-10.jpg", alt: "A golden staircase with fairylights and floral arrangements at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Feb-2025-11.jpg", alt: "A bouquet of roses on a tree branch at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Feb-2025-12.jpg", alt: "A newly married couple walking outdoors at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Feb-2025-13.jpg", alt: "A beautiful modern bride standing in a old building at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Feb-2025-14.jpg", alt: "A floral arrangement at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/Feb-2025-15.jpg", alt: "A chapel aisle with marble floors at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.CORPORATE]: [
    { src: "/images/corporate/Corporate_20.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_21.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_15.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_7.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_24.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_18.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    { src: "/images/bdiamond/Bdmnd_23.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_31.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_36.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_26.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_25.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_5.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    { src: "/images/loveislove/LoveIsLove_2.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_20.jpg", alt: "A beautiful same-sex marriage venue at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_7.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_21.jpg", alt: "A beautiful same-sex marriage venue at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_21.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_24.jpg", alt: "A beautiful same-sex marriage venue at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_1.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_15.jpg", alt: "A beautiful same-sex marriage venue at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_9.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_18.jpg", alt: "A beautiful same-sex marriage venue at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_16.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/loveislove/LoveIsLove_27.jpg", alt: "A beautiful same-sex marriage venue at Shepstone Gardens & The Great Hall" },
  ],
};

const COVER_IMAGE = {
  [ENTRY_POINT.DEFAULT]: [
    "/images/home-header-4.jpg",
  ],
  [ENTRY_POINT.CORPORATE]: [
    "/images/corporate/Corporate_1.jpg",
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    "/images/bdiamond/Bdmnd_2.jpg",
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    "/images/loveislove/LoveIsLove_8.jpg",
  ],
};

const WELCOME_MESSAGE = {
  [ENTRY_POINT.DEFAULT]: [
    "A venue for life's best occasions: the pinnacle of sophistication and grace...",
  ],
  [ENTRY_POINT.CORPORATE]: [
    "Looking for a corporate function venue? We're happy to say: you've found it.",
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    "Now is YOUR time. Make your mark at Johannesburg's most exclusive wedding venue.",
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    "Love is love. We are proud to be Johannesburg's most inclusive wedding venue.",
  ],
};

const styles = {
  followButton: {
    fontSize: "1.5em",
    width: "100%",
    height: "100%",
    textTransform: "none",
  },
};

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>The Shepstone Experience</title>
        <meta
          name={"description"}
          content={"A magnificent group of venues where special occasions are nurtured into unforgettable moments to be cherished forever."}
        ></meta>
      </Helmet>
      
      <div className={"header-section-home-page"}>
        <Header
          bgImg={COVER_IMAGE[getEntryPoint()]}
          // logo={"/images/TSE-Logo-White2.png"}
          headText={"THE SHEPSTONE EXPERIENCE"}
        />
      </div>

      {/* <LineSeparator noLine />
      <Specials specialsList={specialsData} /> */}

      <LineSeparator noLine />
      <BlockHeading
        headText={WELCOME_MESSAGE[getEntryPoint()]}
        noBorder
      />
      <LineSeparator noLine />
      
      <BlockGallery
        images={GALLERY_IMAGES_1[getEntryPoint()]}
      />

      <div className={"home-follow-button"}>
        <Button
          href={"https://www.instagram.com/shepstone_gardens/"}
          target={"_blank"}
          variant={"outlined"}
          color={"primary"}
          style={styles.followButton}
        >
          Follow us
        </Button>
      </div>

      <LineSeparator noLine />
      <LineSeparator noLine />
      <LineSeparator noLine />

      <GreenBlockSection
        headText={"your unique event"}
        subHeadText={"Our venue,"}
      >
        <div className={"three-columns"}>
          <p className={"venue-intro-text"}>
            Defined by our careful attention to detail, service and coordination, The Shepstone Experience offers timelessly stylish and sophisticated venues, perfect for your wedding, event or corporate occasion. You have a beautiful story to tell, and we are here to help you write this chapter.
          </p>
          <p className={"venue-intro-text"}>
            Our incredible team of industry-leading professionals are here to ensure that your event is stress-free and flawless, with an in-house team that will take care of every single one of your unique needs and bring your dream day to life. No vision is too big for us to help you execute.
          </p>
          <p className={"venue-intro-text"}>
            Being a family-owned venue, Shepstone Gardens gives all of our wonderful guests a warm welcome and a service that is personalised. When you arrive at Shepstone Gardens, you become part of our family, and we are there to take care of you every step of the way.
          </p>
        </div>
      </GreenBlockSection>

      <LineSeparator noLine />
      <LineSeparator noLine />

      <VideoSection
        videoUrl={"https://www.youtube.com/embed/wvH9XEyYeB0?si=IKlYO0NjO0prXqO-"}
        headText={"Like no other..."}
        previewImg={"/images/Home-Page-Video-Tile.jpg"}
        noLines
      />

      <BlockHeading
        headText={"Reviews"}
        noBorder
      />
      <Testimonials testList={testimonialsData} />

      <CoverFlowGallery
        images={GALLERY_IMAGES_2[getEntryPoint()]}
      />

    </div>
  );
};

export default Home;

