import "./shepstone.css";

import React from "react";
import { Helmet } from "react-helmet";

import { ENTRY_POINT } from "../../utils/stateTypes";
import { getEntryPoint } from "../../utils/entryStorage";
import Header from "../sections/Header";
import LineSeparator from "../sections/LineSeparator";
import GreenBlockSection from "../sections/GreenBlockSection";
import BlockHeading from "../sections/BlockHeading";
import CoverFlowGallery from "../sections/CoverFlowGallery";
import VideoSection from "../sections/VideoSection";
import Contact from "../sections/Contact";
// import specialsData from "../home/specialsData";
// import Specials from "../sections/Specials";

const GALLERY_IMAGES_1 = {
  [ENTRY_POINT.DEFAULT]: [
    { src: "/images/block-gallery-1/Varene-Burton-(23).jpg", alt: "A bride-to-be laughing as she prepares at Shepstone Gardens" },
    { src: "/images/block-gallery-1/Varene-Burton-(22).jpg", alt: "A bride-to-be laughing as she prepares at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(153).jpg", alt: "A bride-to-be laughing as she prepares at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(152).jpg", alt: "A group of groomsmen smoking a cigar together at Shepstone Gardens" },
    { src: "/images/block-gallery-1/C_T-26(1).jpg", alt: "A bride standing on the balcony of the bridal room at Shepstone Gardens" },
    { src: "/images/block-gallery-1/C_T-37(1).jpg", alt: "A chandelier hanging in the bridal room at Shepstone Gardens" },
    { src: "/images/block-gallery-1/g173.jpg", alt: "A bride in the bath at Shepstone Gardens" },
  ],
  [ENTRY_POINT.CORPORATE]: [
    { src: "/images/corporate/Corporate_15.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_18.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_19.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_20.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    { src: "/images/bdiamond/Bdmnd_6.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_11.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_20.jpg", alt: "A beautiful event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_20.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    { src: "/images/block-gallery-1/SG-img-(153).jpg", alt: "A bride-to-be laughing as she prepares at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(152).jpg", alt: "A group of groomsmen smoking a cigar together at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(79).jpg", alt: "A bride holding red flowers in her dress at Shepstone Gardens" },
    { src: "/images/block-gallery-1/C_T-26(1).jpg", alt: "A bride standing on the balcony of the bridal room at Shepstone Gardens" },
    { src: "/images/block-gallery-1/C_T-37(1).jpg", alt: "A chandelier hanging in the bridal room at Shepstone Gardens" },
    { src: "/images/block-gallery-1/g173.jpg", alt: "A bride in the bath at Shepstone Gardens" },
  ],
};

const GALLERY_IMAGES_2 = {
  [ENTRY_POINT.DEFAULT]: [
    { src: "/images/block-gallery-1/Feb-2025-18.jpg", alt: "An outdoor ceremony at Shepstone Gardens" },
    { src: "/images/block-gallery-1/I_T-8764.jpg", alt: "An outdoor ceremony at Shepstone Gardens" },
    { src: "/images/block-gallery-1/I_T-8761.jpg", alt: "A mixed race bridal couple kiss at Shepstone Gardens" },
    { src: "/images/block-gallery-1/Varene-Burton-(17).jpg", alt: "Bridesmaids laugh at Shepstone Gardens" },
    { src: "/images/block-gallery-1/Feb-2025-19.jpg", alt: "A white carpet outside the Chapel at Shepstone Gardens" },
    { src: "/images/block-gallery-1/I_T-8762.jpg", alt: "A bride and groom walk through white confetti at Shepstone Gardens" },
    { src: "/images/block-gallery-1/I_T-8765.jpg", alt: "Beautiful white ceremony chairs outdoors in the sunlight at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(15).jpg", alt: "A white carpet outside the Chapel at Shepstone Gardens" },
    { src: "/images/block-gallery-1/Varene-Burton-(20).jpg", alt: "A couple celebrate their first kiss at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(16).jpg", alt: "A happy couple walking out from their ceremony at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(37).jpg", alt: "A minister and a newly wed couple saying their vows outdoors at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(81).jpg", alt: "Family and friends watch a heart-warming wedding ceremony at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(85).jpg", alt: "A newly married bride and groom walk out of the Chapel at Shepstone Gardens" },
  ],
  [ENTRY_POINT.CORPORATE]: [
    { src: "/images/corporate/Corporate_22.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_23.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_24.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_5.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    { src: "/images/bdiamond/Bdmnd_4.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_13.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_21.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_22.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_25.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    { src: "/images/loveislove/LoveIsLove_6.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/I_T-8764.jpg", alt: "An outdoor ceremony at Shepstone Gardens" },
    { src: "/images/loveislove/LoveIsLove_22.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/I_T-8765.jpg", alt: "Beautiful white ceremony chairs outdoors in the sunlight at Shepstone Gardens" },
    { src: "/images/loveislove/LoveIsLove_4.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(15).jpg", alt: "A white carpet outside the Chapel at Shepstone Gardens" },
    { src: "/images/loveislove/LoveIsLove_17.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(81).jpg", alt: "Family and friends watch a heart-warming wedding ceremony at Shepstone Gardens" },
    { src: "/images/loveislove/LoveIsLove_19.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
  ],
};

const GALLERY_IMAGES_3 = {
  [ENTRY_POINT.DEFAULT]: [
    { src: "/images/block-gallery-1/SG-img-(64).jpg", alt: "A hanging floral arrangment at a wedding reception at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(185).jpg", alt: "The Chapel reflecting on the glass of the banquet hall at Shepstone Gardens" },
    { src: "/images/block-gallery-1/Varene-Burton-(18).jpg", alt: "Ballerinas at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(200).jpg", alt: "A reception table decorated in the Glass Marquee at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(75).jpg", alt: "A stunning floral centerpiece at a wedding reception at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(35).jpg", alt: "A beautiful white and gold wedding cake at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(30).jpg", alt: "A night-time wedding reception at Shepstone Gardens" },
    { src: "/images/block-gallery-1/_DA80771.jpg", alt: "A huge tree with fairy lights hanging from it at Shepstone Gardens" },
    { src: "/images/block-gallery-1/AlexanderSmith-623.jpg", alt: "A best man gives a heartwarming speech at Shepstone Gardens" },
    { src: "/images/block-gallery-1/AlexanderSmith-152.jpg", alt: "A reception table decorated in the Glass Marquee at Shepstone Gardens" },
    { src: "/images/block-gallery-1/AlexanderSmith-733.jpg", alt: "The bride and groom of a wedding laugh at speech jokes at Shepstone Gardens" },
    { src: "/images/block-gallery-1/andreacronje-L_J-previews-23.jpg", alt: "The glass roof of the marquee at Shepstone Gardens" },
    { src: "/images/block-gallery-1/AlexanderSmith-634.jpg", alt: "A crowd raises their glasses to the newly-weds at Shepstone Gardens" },
  ],
  [ENTRY_POINT.CORPORATE]: [
    { src: "/images/corporate/Corporate_8.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_9.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_10.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_14.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    { src: "/images/bdiamond/Bdmnd_3.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_18.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_24.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_29.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_35.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_37.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    { src: "/images/block-gallery-1/SG-img-(64).jpg", alt: "A hanging floral arrangment at a wedding reception at Shepstone Gardens" },
    { src: "/images/loveislove/LoveIsLove_24.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(185).jpg", alt: "The Chapel reflecting on the glass of the banquet hall at Shepstone Gardens" },
    { src: "/images/loveislove/LoveIsLove_3.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(200).jpg", alt: "A reception table decorated in the Glass Marquee at Shepstone Gardens" },
    { src: "/images/loveislove/LoveIsLove_28.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(75).jpg", alt: "A stunning floral centerpiece at a wedding reception at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(35).jpg", alt: "A beautiful white and gold wedding cake at Shepstone Gardens" },
    { src: "/images/loveislove/LoveIsLove_5.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(30).jpg", alt: "A night-time wedding reception at Shepstone Gardens" },
    { src: "/images/loveislove/LoveIsLove_27.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/_DA80771.jpg", alt: "A huge tree with fairy lights hanging from it at Shepstone Gardens" },
    { src: "/images/loveislove/LoveIsLove_29.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith-152.jpg", alt: "A reception table decorated in the Glass Marquee at Shepstone Gardens" },
    { src: "/images/block-gallery-1/andreacronje-L_J-previews-23.jpg", alt: "The glass roof of the marquee at Shepstone Gardens" },
    { src: "/images/loveislove/LoveIsLove_2.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/AlexanderSmith-634.jpg", alt: "A crowd raises their glasses to the newly-weds at Shepstone Gardens" },
    { src: "/images/block-gallery-1/AlexanderSmith-623.jpg", alt: "A best man gives a heartwarming speech at Shepstone Gardens" },
  ],
};

const GALLERY_IMAGES_4 = {
  [ENTRY_POINT.DEFAULT]: [
    { src: "/images/block-gallery-1/Feb-2025-22.jpg", alt: "A bride and groom dancing at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(49).jpg", alt: "A cocktail bar at Shepstone Gardens" },
    { src: "/images/block-gallery-1/SG-img-(178).jpg", alt: "The bride and groom on the dancefloor at Shepstone Gardens" },
    { src: "/images/block-gallery-1/g204.jpg", alt: "A groom lifting up his bride on the dancefloor at Shepstone Gardens" },
    { src: "/images/block-gallery-1/AlexanderSmith-698.jpg", alt: "A crowd toasts together at Shepstone Gardens" },
    { src: "/images/block-gallery-1/I_T-1663.jpg", alt: "A happily married couple open the dancefloor at Shepstone Gardens" },
  ],
  [ENTRY_POINT.CORPORATE]: [
    { src: "/images/corporate/Corporate_7.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_25.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_7.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
    { src: "/images/corporate/Corporate_26.jpg", alt: "A beautiful corporate event setup at Shepstone Gardens & The Great Hall" },
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    { src: "/images/bdiamond/Bdmnd_14.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_39.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_36.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_27.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(49).jpg", alt: "A cocktail bar at The Great Hall" },
    { src: "/images/bdiamond/Bdmnd_26.jpg", alt: "A newly married African couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-Img-Party.jpg", alt: "A bartender pours drinks for the wedding party at The Great Hall" },
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    { src: "/images/loveislove/LoveIsLove_26.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(49).jpg", alt: "A cocktail bar at Shepstone Gardens" },
    { src: "/images/loveislove/LoveIsLove_30.jpg", alt: "A same-sex marriage couple at Shepstone Gardens & The Great Hall" },
    { src: "/images/block-gallery-1/SG-img-(178).jpg", alt: "The bride and groom on the dancefloor at Shepstone Gardens" },
    { src: "/images/block-gallery-1/AlexanderSmith-698.jpg", alt: "A crowd toasts together at Shepstone Gardens" },
  ],
};

const HEADING_1 = {
  [ENTRY_POINT.DEFAULT]: [
    "Before your I Do's",
  ],
  [ENTRY_POINT.CORPORATE]: [
    "Exquisite banqueting setups",
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    "Before your I Do's",
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    "Before your I Do's",
  ],
};

const HEADING_2 = {
  [ENTRY_POINT.DEFAULT]: [
    "The Ceremony",
  ],
  [ENTRY_POINT.CORPORATE]: [
    "Classy conferencing",
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    "The Ceremony",
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    "The Ceremony",
  ],
};

const HEADING_3 = {
  [ENTRY_POINT.DEFAULT]: [
    "The Reception",
  ],
  [ENTRY_POINT.CORPORATE]: [
    "Out of this world",
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    "The Reception",
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    "The Reception",
  ],
};

const HEADING_4 = {
  [ENTRY_POINT.DEFAULT]: [
    "Dance the night away",
  ],
  [ENTRY_POINT.CORPORATE]: [
    "An event they'll never forget",
  ],
  [ENTRY_POINT.BLACK_DIAMOND]: [
    "Dance the night away",
  ],
  [ENTRY_POINT.LOVE_IS_LOVE]: [
    "Dance the night away",
  ],
};

const GreatHall = () => {
  return (
    <div>
      <Helmet>
        <title>Shepstone Gardens | Weddings, Special Occasions & Corporate Functions</title>
        <meta
          name={"description"}
          content={"A magnificent venue where special occasions are nurtured into unforgettable moments to be cherished forever."}
        ></meta>
      </Helmet>
    
      <Header
        bgImg={"/images/header-shepstone-gardens-2.jpg"}
        headText={"SHEPSTONE GARDENS"}
      />

      {/* <LineSeparator noLine />
      <Specials specialsList={specialsData} /> */}

      <LineSeparator noLine />
      <LineSeparator noLine />

      <GreenBlockSection
        headText={"Shepstone Gardens"}
        subHeadText={"Welcome to"}
      >
        <div className={"three-columns"}>
          <p className={"venue-intro-text"}>
            Welcome to the story of Shepstone Gardens – a tale of how a family home blossomed into a magnificent venue where special occasions are nurtured into unforgettable moments to be cherished forever. Enjoy a romanticised walk down through the rose gardens as you approach the aisle, while being engulfed by the beautiful African sunshine and surrounding greenery.
          </p>
          <p className={"venue-intro-text"}>
            Striking and elegant are only a few of the words one could use to describe the Glass Marquee. The marbled floors, stone pillars and chandeliers which not only add a sense of opulence and timelessness to your wedding day, but also create the perfect backdrop to let your imagination run wild and your dreams come alive.
          </p>
          <p className={"venue-intro-text"}>
            Our on-site Chapel with its intricate stone walls, large stained-glass windows and magnificent glass roof sits amidst our leafy gardens and is truly a romantic space to say “I Do”. Or perhaps you'd like to say your vows outdoors under the gorgeous skies watching over our private mountain, in our Natural Arch? The choice is yours, either way.
          </p>
        </div>
      </GreenBlockSection>
      
      <LineSeparator noLine />
      <LineSeparator noLine />
      <LineSeparator />
      <BlockHeading
        headText={HEADING_1[getEntryPoint()]}
        noBorder
      />

      <CoverFlowGallery
        images={GALLERY_IMAGES_1[getEntryPoint()]}
        noOvButton
      />

      <LineSeparator />
      <BlockHeading
        headText={HEADING_2[getEntryPoint()]}
        noBorder
      />

      <CoverFlowGallery
        images={GALLERY_IMAGES_2[getEntryPoint()]}
        noOvButton
      />

      <LineSeparator />
      <BlockHeading
        headText={HEADING_3[getEntryPoint()]}
        noBorder
      />

      <CoverFlowGallery
        images={GALLERY_IMAGES_3[getEntryPoint()]}
        noOvButton
      />

      <LineSeparator />
      <BlockHeading
        headText={HEADING_4[getEntryPoint()]}
        noBorder
      />

      <CoverFlowGallery
        images={GALLERY_IMAGES_4[getEntryPoint()]}
        noOvButton
      />

      <VideoSection
        videoUrl={"https://www.youtube.com/embed/5saaUXI7d-E"}
        headText={"Where dreams come true"}
        previewImg={"/images/SG-Video-Tile.jpg"}
        noLines
      />

      <LineSeparator />
      <Contact />
    </div>
  );
};

export default GreatHall;