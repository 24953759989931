import React from "react";
import { Helmet } from "react-helmet";

import Header from "../sections/Header";
import LineSeparator from "../sections/LineSeparator";
import BlockGallery from "../sections/BlockGallery";
import CoverFlowGallery from "../sections/CoverFlowGallery";
import GreenBlockSection from "../sections/GreenBlockSection";
import Contact from "../sections/Contact";
// import specialsData from "../home/specialsData";
// import Specials from "../sections/Specials";

const About = () => {
  return (
    <div>
      <Helmet>
        <title>Décor & Floral Services | The Shepstone Experience</title>
        <meta
          name={"description"}
          content={"Our in-house floral and décor specialists will take your dream event and make it a beautiful reality."}
        ></meta>
      </Helmet>

      <Header
        bgImg={"/images/floral-header-3.jpg"}
        headText={"FLORALS AT SHEPSTONE GARDENS"}
      />

      {/* <LineSeparator noLine />
      <Specials specialsList={specialsData} /> */}

      <LineSeparator noLine />
      <LineSeparator noLine />

      <GreenBlockSection
        headText={"day"}
        subHeadText={"A picture perfect"}
      >
        <div className={"three-columns"}>
          <p className={"venue-intro-text"}>
            Our incredible in-house décor and floral team understand how to flawlessly blend your chosen wedding style with the breathtaking surroundings of Shepstone Gardens and The Great Hall, bringing your dream look to life.
          </p>
          <p className={"venue-intro-text"}>
            Mira and her team do not only create flawless, picturesque moments for your special day, but translate your dream into reality. Walking the journey alongside you, they will guide and inspire every step of the way.
          </p>
          <p className={"venue-intro-text"}>
            A team that combines their love for the industry and floral art, with their appreciation and respect for each couple and their unique vision. You have a beautiful story to tell, and they are here to help you write this chapter.
          </p>
        </div>
      </GreenBlockSection>

      <LineSeparator noLine />

      <BlockGallery
        images={[
          { src: "/images/block-gallery-1/Feb-2025-16.jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(66).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(71).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(75).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },

          { src: "/images/block-gallery-1/SG-img-(84).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(88).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(133).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(137).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },

          { src: "/images/block-gallery-1/SG-img-(182).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(191).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(184).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(193).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },

          { src: "/images/block-gallery-1/SG-img-(206).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(189).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(198).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(157).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
        ]}
      />

      <LineSeparator noLine />
      <LineSeparator />
      <Contact />

      <CoverFlowGallery
        images={[
          { src: "/images/block-gallery-1/Floral-New-(1).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(2).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(3).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(4).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(5).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(6).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(7).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(8).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(9).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(10).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(11).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(12).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(13).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(14).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(15).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/Floral-New-(16).jpg", alt: "A weddding floral arrangement at Shepstone Gardens & The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(8).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(33).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(39).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(56).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
          { src: "/images/block-gallery-1/SG-img-(70).jpg", alt: "Flowers at Shepstone Gardens and The Great Hall" },
        ]}
      />
    </div>
  );
};

export default About;

