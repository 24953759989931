import "./blogHome.css";
import { Helmet } from "react-helmet";

import React from "react";

import blogPosts from "./blogData";
import Header from "../sections/Header";
import BlogPreview from "./BlogPreview";
import LineSeparator from "../sections/LineSeparator";
import GreenBlockSection from "../sections/GreenBlockSection";

const BlogHome = () => {
  return (
    <div>
      <Helmet>
        <title>Blog | The Shepstone Experience</title>
        <meta
          name={"description"}
          content={"Have a look at some amazing stories from across the gorgeous Shepstone venues."}
        ></meta>
      </Helmet>

      <Header
        bgImg={"/images/blog-header-3.jpg"}
        headText={"PLANNED TO PERFECTION"}
      />

      <LineSeparator noLine />
      <LineSeparator noLine />

      <GreenBlockSection
        headText={"our stories"}
        subHeadText={"Immerse yourself in"}
      >
        <div className={"three-columns"}>
          <p className={"venue-intro-text"}>
            The team at The Shepstone Experience has had decades worth of experience planning perfect fairytale weddings.
          </p>
          <p className={"venue-intro-text"}>
            With all that knowledge and wisdom, it would be a crime not to share it with anyone planning their perfect day!
          </p>
          <p className={"venue-intro-text"}>
            Check back often to read our latest helpful articles about every single aspect of your wedding day.
          </p>
        </div>
      </GreenBlockSection>

      <section className={"blog-previews standard-width"}>
        {
          blogPosts.map((post, index) => (
            <BlogPreview
              key={index}
              post={post}
              isFeatured={((index % 10) === 0) || (((index % 10) % 6) === 0)}
            />
          ))
        }
      </section>
    </div>
  );
};

export default BlogHome;

