import React from "react";
import { Helmet } from "react-helmet";

import "./about.css";

import Header from "../sections/Header";
import LineSeparator from "../sections/LineSeparator";
import BlockHeading from "../sections/BlockHeading";
import IconsGroup from "../sections/IconsGroup";
import GreenBlockSection from "../sections/GreenBlockSection";
import Maps from "../sections/Maps";
import Contact from "../sections/Contact";
import CoverFlowGallery from "../sections/CoverFlowGallery";
// import Team from "../sections/Team";

const ABOUT_IMAGES = {
  [0]: [
    { src: "/images/team/SG-about-gallery-1.jpg", alt: "An outdoor gin bar at Shepstone Gardens & The Great Hall" },
    { src: "/images/team/SG-about-gallery-2.jpg", alt: "A florist setting up a table center piece for a wedding at Shepstone Gardens & The Great Hall" },
    { src: "/images/team/SG-about-gallery-3.jpg", alt: "A champagne tower at Shepstone Gardens & The Great Hall" },
    { src: "/images/team/SG-about-gallery-4.jpg", alt: "An arrangement of white roses at Shepstone Gardens & The Great Hall" },
  ],
};

const About = () => {
  return (
    <div>
      <Helmet>
        <title>The story of our venue | The Shepstone Experience</title>
        <meta
          name={"description"}
          content={"Learn more about the magical Shepstone Experience and its excellent team."}
        ></meta>
      </Helmet>

      <Header
        bgImg={"/images/team_header-2.jpg"}
        headText={"GET TO KNOW US A LITTLE BETTER"}
      />

      {/* They are hiding the individual team members for now until they've done a photoshoot */}
      {/* <Team
        teams={[
          {
            name: "Sales",
            icon: "/images/team/sales-icon.png",
            members: [
              {
                img: "/images/team/becky.jpg",
                name: "Becky Harman",
                position: "Sales manager",
              },
              {
                img: "/images/team/placeholder.jpg",
                name: "Babalwa Mayiyane",
                position: "Sales",
              },
            ],
          },
          {
            name: "Wedding planners",
            icon: "/images/team/planners-icon.png",
            members: [
              {
                img: "/images/team/jess.jpg",
                name: "Jessica Gaisford",
                position: "Wedding planner",
              },
              {
                img: "/images/team/sinead.jpg",
                name: "Sinead Roberts",
                position: "Wedding planner",
              },
            ],
          },
          {
            name: "Floral & decor",
            icon: "/images/team/decor-icon.png",
            members: [
              {
                img: "/images/team/mira.jpg",
                name: "Mira Cameron",
                position: "Head Floral & Decor Stylist",
              },
              {
                img: "/images/team/placeholder.jpg",
                name: "Mariska",
                position: "Floral & Decor Stylist",
              },
              {
                img: "/images/team/placeholder.jpg",
                name: "Mercy",
                position: "Floral & Decor Stylist",
              },
              {
                img: "/images/team/placeholder.jpg",
                name: "Carlton",
                position: "Floral & Decor Stylist",
              },
            ],
          },
          {
            name: "Accounts",
            icon: "/images/team/accounts-icon.png",
            members: [
              {
                img: "/images/team/amanda.jpg",
                name: "Amanda Verster",
                position: "Accounts manager",
              },
              {
                img: "/images/team/alex.jpg",
                name: "Alex Watermeyer",
                position: "Accounts",
              },
              {
                img: "/images/team/placeholder.jpg",
                name: "Diane Tait",
                position: "Accounts",
              },
            ],
          },
          {
            name: "DJs",
            icon: "/images/team/dj-icon.png",
            members: [
              {
                img: "/images/team/placeholder.jpg",
                name: "Henk Kotze",
                position: "DJ",
              },
              {
                img: "/images/team/placeholder.jpg",
                name: "Dylan Rix",
                position: "DJ",
              },
            ],
          },
          {
            name: "Management",
            icon: "/images/team/management-icon.png",
            members: [
              {
                img: "/images/team/ken.jpg",
                name: "Ken Shepstone Rayner",
                position: "Chief delegator",
              },
              {
                img: "/images/team/mira.jpg",
                name: "Mira Cameron",
                position: "Venue manager",
              },
              {
                img: "/images/team/becky.jpg",
                name: "Becky Harman",
                position: "Sales manager",
              },
              {
                img: "/images/team/amanda.jpg",
                name: "Amanda Verster",
                position: "Accounts manager",
              },
            ],
          },
          {
            name: "Service",
            icon: "/images/team/service-icon.png",
            members: [
              {
                img: "/images/team/placeholder.jpg",
                name: "Nqobile",
                position: "Service team",
              },
              {
                img: "/images/team/placeholder.jpg",
                name: "Sibu",
                position: "Service team",
              },
              {
                img: "/images/team/placeholder.jpg",
                name: "Rejoice",
                position: "Service team",
              },
              {
                img: "/images/team/placeholder.jpg",
                name: "Thuba",
                position: "Service team",
              },
              {
                img: "/images/team/placeholder.jpg",
                name: "Autrace",
                position: "Service team",
              },
            ],
          },
        ]}
      /> 
      
      <LineSeparator />*/}

      {/* Temp banner image --------------------------*/}
      {/* <LineSeparator noLine />
      <img
        src={"/images/team/team_head3.JPG"}
        className={"banner-image"}
      />
      <br/><br/>
      <LineSeparator /> */}
      {/*--------------------------*/}

      <LineSeparator noLine />
      <BlockHeading
        headText={"Our offerings"}
        noBorder
      />

      <IconsGroup
        icons={[
          {
            img: "/images/icon-group-1/Venues.png",
            label: "Venues",
          },
          {
            img: "/images/icon-group-1/Florals.png",
            label: "Florals",
          },
          {
            img: "/images/icon-group-1/Bar.png",
            label: "Bar",
          },
          {
            img: "/images/icon-group-1/Catering.png",
            label: "Catering",
          },
          {
            img: "/images/icon-group-1/DJ.png",
            label: "DJ",
          },
          {
            img: "/images/icon-group-1/Decor.png",
            label: "Decor",
          },
        ]}
      />

      <LineSeparator noLine />

      <GreenBlockSection
        headText={"Shepstone history"}
        subHeadText={"A little bit of"}
      >
        <div className={"three-columns"}>
          <p className={"venue-intro-text"}>
            It all started with Shepstone Gardens... that magical venue on the mountain! Situated in Hope Road, the heart of Mountain View, Shepstone Gardens is a part of a unique time in the heritage of Johannesburg. The main house is one of the original Witwatersrand Ridge houses commissioned by the Modderfontein Dynamite Company after the Anglo Boer War, at the turn of the 20th Century. The original house was built from the local quartz [estimated at 350 million years old] by Afrikaans prisoners captured during the Anglo Boer War.
          </p>
          <p className={"venue-intro-text"}>
            Mahatma Ghandi himself was involved in a dispute between the dynamite company and the prisoners, as he was a close friend of the local architect. Shepstone Gardens, also informally known as the Secret Garden, is a testament to the aesthetic sensibilities of the artisans, gardeners and stone craftsmen who have dedicated their skills to the construction of this landmark. The property has been privately owned by the Rayner family for almost 35 years now, but they have maintained the style of the original Herbert Baker architecture.
          </p>
          <p className={"venue-intro-text"}>
            It is now an established venue with a sterling reputation that competes with its own historical glory. Being a privately owned venue, Shepstone Gardens gives all its guests a warm welcome and service that is personalised and taken seriously. Here, the priority is to maintain quality relationships and build new ones based on trust. When you visit Shepstone Gardens, you really do feel a part of the family, and can have confidence that you will be taken care of in every aspect. We look forward to adding YOUR mark to our venue.
          </p>
        </div>
      </GreenBlockSection>

      <br/><br/><br/>

      <CoverFlowGallery
        images={ABOUT_IMAGES[0]}
        noOvButton
      />

      <GreenBlockSection
        headText={"Where to find us"}
      >
        <Maps
          mapsList={[
            {
              name: "",
              src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.8688625250325!2d28.076721315029285!3d-26.168399983453803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950db814ea8fbb%3A0x7d851f6cb0a67eb0!2sShepstone+Gardens!5e0!3m2!1sen!2sza!4v1531310599383",
            },
          ]}
        />
      </GreenBlockSection>

      <LineSeparator noLine />
      <LineSeparator />
      <Contact />
    </div>
  );
};

export default About;

